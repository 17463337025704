@use "sass:math";
@import '../../../../config/variables.scss';
@import '../../../../config/mixins.scss';

.modal-content {
  position: relative;
  z-index: 1;
  padding: $main-spacing;
  padding-top: $main-spacing;
  transition: $transition-time;
  overflow: auto;
  height: 100%;
  box-shadow: 0 0 $main-spacing * 1.5 $main-spacing / -2 rgba(0, 0, 0, 0.5);
  background-color: $color-background;
  height: 100%;
  width: 100%;
  // padding-top: $main-spacing * 3;
  @include gt-sm {
    min-width: 500px;
  }
}

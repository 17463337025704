@import "../../../config/variables";
@import "../../../config/mixins";

.simple-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .simple-header {
    color: $color-text;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .no-margin {
    margin-bottom: $main-spacing;
  }
}

@include gt-sm {
  .section-header-container {

    .simple-header {
      max-width: $page-header-width;
    }
  }
}

@use "sass:math";
@import './variables';
@import './mixins.scss';

html {
  font-size: 14px;
  font-family: $font-primary;
  height: 100%;
}

@include gt-sm {
  html {
    font-size: 16px;
  }
}

#root {
  height: 100%;
  overflow: auto;
}

* {
  font-family: $font-primary;
}

body {
  position: relative;
  font-weight: 300;
  color: $color-text;
  font-family: $font-primary;
  line-height: normal;
  background-color: $color-background;
  height: 100%;
}

h2 {
  font-family: $font-primary;
  font-size: 1.1rem;
  font-weight: 500;

  @include gt-sm {
    font-size: 1.3rem;
  }
}

h3 {
  font-family: $font-primary;
  font-size: 1.05rem;
  font-weight: 500;
  margin-bottom: $main-spacing * 1;

  &.no-margin {
    margin-bottom: 0;
  }
}

h4 {
  font-family: $font-primary;
  font-size: 1.1rem;
  margin-bottom: math.div($main-spacing, 2);
  font-weight: 400;
}

h5 {
  font-family: $font-primary;
  font-size: 1.1rem;
  margin-bottom: math.div($main-spacing, 2);
}

strong,
b {
  font-weight: 700;
}

small {
  font-size: 0.8rem;
}

p {
  line-height: normal;
  margin: math.div($main-spacing, 2) 0;
  font-size: 0.9rem;

  @include gt-sm {
    font-size: 1rem;
  }

  &.info-text {
    font-size: 0.8rem;
    text-align: left;
  }
}

a {
  transition: 0.3s color;
  text-decoration: none;

  &:visited,
  &:active {
    color: $color-text;
  }

  &.light {
    text-decoration: underline;
    &:link {
      color: $color-text;
    }

    &:hover {
      color: darken($color-text, 10%);
    }

    &:visited,
    &:active {
      color: $color-text;
    }
  }
}

.go-back-link {
  margin-bottom: $main-spacing;
  display: block;
}

pre {
  padding: $main-spacing;
  font-family: monospace;
  border: 1px solid lighten($color-background, 20%);
  margin: $main-spacing 0;
  word-wrap: break-word;
  overflow-x: auto;
  white-space: pre-wrap;
}

em,
i {
  font-style: italic;
}

@import '../../../../../config/variables.scss';
@import '../../../../../config/mixins.scss';

.step-size-wrapper {
  form {
    width: 100%;
  }

  .options-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}

@include gt-sm {
  .options-wrapper {
    flex-direction: row;
    margin-bottom: $main-spacing * 2;
  }
}

@import '../../../../../config/variables.scss';
@import '../../../../../config/mixins.scss';

.order-summary-wrapper {
  width: 100%;

  .rules-embed {
    width: 100%;
    height: 450px;
  }
}
@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.checkbox-disabled {

  label {
    &:hover {
      cursor: not-allowed;
    }
  }
}

.checkbox-hide {
  label {
    &:before{
      display: none;
    }
  }
}
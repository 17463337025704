@import '../../../../config/variables.scss';
@import '../../../../config/mixins.scss';

$icon-size: $main-spacing * 4;

.item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 10;
  font-weight: 600;
  font-size: 1.1rem;

  .item-icon {
    width: $icon-size;
    height: $icon-size;
    border-radius: $icon-size / 2;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 10px -5px lighten(rgba(#000, 0.4), 30%);
  }

  .item-current {
    color: $color-text-button;

    &::after {
      display: block;
      content: '';
      background-image: url('/assets/icons/bar-marker.svg');
      background-size: contain;
      background-repeat: no-repeat;
      fill: #aaa;
      width: 16px;
      height: 16px;
      color: $color-disabled;
      top: -16px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
    }
  }

  .item-description {
    margin-top: $main-spacing;
    font-size: 0.7rem;
    font-weight: 400;
  }
}

@include gt-sm {
  .ittem-wrapper {
    .item-description {
      font-size: 1rem;
      font-weight: 400;
    }

    .item-icon {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }
}

@use "sass:math";
@import '../../../config/variables.scss';

.input-label {

    color: $color-text;
    font-size: 0.85rem;
    font-weight: 500;
    margin-bottom: math.div($main-spacing, 4);

    &.big {
        margin-left: $main-spacing * 3;
    }
  }
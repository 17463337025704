@import '../../../../../../config/variables.scss';

.lock-info {
  text-align: center;
  max-width: 250px;
  margin: $main-spacing * 2 auto;

  .lock-info-text {
    margin: $main-spacing * 2 0;
    font-weight: 500;
  }

  img {
    max-width: 100px;
  }
}

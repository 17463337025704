@use "sass:math";
@import '../../../config/variables.scss';

.input-error {
    color: $color-danger;
    font-size: 0.85rem;
    font-weight: 500;

    &.big {
        margin-left: $main-spacing * 3;
    }
  }
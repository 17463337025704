@import '../../../config/variables';
@import '../../../config/mixins.scss';

.page-content {
  padding: 0;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  background-color: $color-background;
  width: 100%;
  @include safe-padding-bottom(0px);
  @include safe-padding-top(0px);

  &.bg {
    background-size: cover;
    background-position: center;
  }
}

@include gt-xs {
  .page-content {
    padding: $main-spacing * 2 $main-spacing * 2;
    @include safe-padding-bottom($main-spacing * 2);
    @include safe-padding-top($main-spacing * 2);
  }
}

@include gt-sm {
  .page-content {
    padding: $main-spacing $main-spacing * 3;
  }
}

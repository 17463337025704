@import '../../../config/variables';
@import '../../../config/mixins';

.form-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .form-header {
    color: $color-text;
    display: flex;
    align-items: center;
    width: 100%;
    margin: $main-spacing * 1.5 0;
  }
}

@include gt-sm {
  .form-header-container {
    .form-header {
      max-width: $page-header-width;
    }
  }
}

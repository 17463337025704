@import './variables.scss';

@mixin or-port {
  @media  screen and (orientation: portrait){
    @content;
  }
}

@mixin or-land {
  @media screen and (orientation: landscape){
    @content;
  }
}

@mixin lt-xs {
  @media screen and (max-width: map-get($breakpoints, xs) - 1) {
    @content;
  }
}

@mixin lt-sm {
  @media screen and (max-width: map-get($breakpoints, sm) - 1) {
    @content;
  }
}

@mixin lt-md {
  @media screen and (max-width: map-get($breakpoints, md) - 1) {
    @content;
  }
}

@mixin gt-xs {
  @media screen and (min-width: map-get($breakpoints, xs)) {
    @content;
  }
}

@mixin gt-sm {
  @media screen and (min-width: map-get($breakpoints, sm)) {
    @content;
  }
}

@mixin gt-md {
  @media screen and (min-width: map-get($breakpoints, md)) {
    @content;
  }
}

@mixin gt-lg {
  @media screen and (min-width: map-get($breakpoints, lg)) {
    @content;
  }
}

@mixin gt-xl {
  @media screen and (min-width: map-get($breakpoints, xl)) {
    @content;
  }
}

@mixin thumb {
  border: 1px solid #000000;
  height: 24px;
  width: 24px;
  border-radius: 3px;
  background: $color-background;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  margin-top: -8px;
}

@mixin safe-padding-bottom($val) {
  @supports (padding-bottom: constant(safe-area-inset-bottom)) {
    padding-bottom: calc(#{$val} + constant(safe-area-inset-bottom));
  }
  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    padding-bottom: calc(#{$val} + env(safe-area-inset-bottom));
  }
}

@mixin safe-padding-top($val) {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-top: calc(#{$val} + constant(safe-area-inset-top));
  }
  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-top: calc(#{$val} + env(safe-area-inset-top));
  }
}

@mixin safe-height-with-bottom-inset($val) {
  @supports (height: constant(safe-area-inset-bottom)) {
    height: calc(#{$val} + constant(safe-area-inset-bottom));
  }
  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (height: env(safe-area-inset-bottom)) {
    height: calc(#{$val} + env(safe-area-inset-bottom));
  }
}

@mixin safe-height-with-top-inset($val) {
  @supports (height: constant(safe-area-inset-top)) {
    height: calc(#{$val} + constant(safe-area-inset-top));
  }
  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (height: env(safe-area-inset-top)) {
    height: calc(#{$val} + env(safe-area-inset-top));
  }
}

@mixin safe-top($val) {
  @supports (top: constant(safe-area-inset-top)) {
    top: calc(#{$val} + constant(safe-area-inset-top));
  }
  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (top: env(safe-area-inset-top)) {
    top: calc(#{$val} + env(safe-area-inset-top));
  }
}

@mixin safe-bottom($val) {
  @supports (bottom: constant(safe-area-inset-bottom)) {
    bottom: calc(#{$val} + constant(safe-area-inset-bottom));
  }
  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (bottom: env(safe-area-inset-bottom)) {
    bottom: calc(#{$val} + env(safe-area-inset-bottom));
  }
}

@import '../../../../config/variables.scss';
@import '../../../../config/mixins.scss';

.detail-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .single {
    display: flex;

    strong {
      margin-left: $main-spacing /2;
    }

    .fa {
      flex: 0 0 12%;
      justify-content: center;
      align-items: center;
      font-size: 1.3rem;
      display: flex;
    }
  }
  .custom-icon-button {
    display: flex;
    flex-direction: row;

    .button-img {
      margin-right: $main-spacing;
    }
  }
  .rotate {
    .button-img {
      transform: rotate(180deg);
    }
  }
}

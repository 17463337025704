@use 'sass:math';
@import '../../../config/variables'; 

.file-wrapper {
  border: 1px dashed $color-primary;
  padding: $main-spacing * 1.5;
  text-align: center;
  cursor: pointer;
  transition: $transition-time;
  border-radius: math.div($main-spacing, 2);
  background-color: rgba(darken($color-background, 0%), 0.1);
  &:hover {
    background-color: rgba(($color-primary), 0.1);
  }

  .file-input-icon {
    width: 3rem;
  }

  img {
    max-height: 350px;
    max-width: 350px;
    width: 100%;
    object-fit: contain;
  }

  p {
    margin-top: $main-spacing;
    font-size: 0.9rem;
    word-break: break-all;
  }

  input {
    display: none;
  }
}

.input-camera {
    display: none;
    // visibility: hidden;
}

.camera-input-button {
    border-radius: math.div($main-spacing, 2);
    padding: math.div($main-spacing, 2);
    background-color: $color-primary;
    text-align: center;
    cursor: pointer;
}

.input-camera-container {
    width: 100%;
    margin: $main-spacing 0;
}

.form-field-title,
.input-name {
  font-size: 0.9rem;
  margin-bottom: math.div($main-spacing, 2);
  display: block;
}

.single-selected-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: math.div($main-spacing, 4) 0;

  span {
    flex: 0 1 100%;
  }

  .single-selected-file-preview {
    max-width: 100px;
    margin-right: $main-spacing;
    border-radius: math.div($main-spacing, 4);
  }
}

.file-container {
  &.dragging {
    background: $color-background;
  }
}

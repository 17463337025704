@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.fields-column {
  display: flex;
  margin: 0 auto;
  width: 100%;

  form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1;
    overflow: hidden;

    .form-field {
      display: flex;
      flex: 1 0 100%;
      padding: $main-spacing 0;
    }

    .form-buttons {
      flex: 1 0 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .form-field-title {
      font-size: 0.8rem;
    }

    @include gt-md {
      .form-field {
        flex: 0 0 100%;
      }
    }

    @include gt-lg {
      .form-field {
        flex: 0 0 100%;
      }
    }
  }
}

@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  // padding: 0 $main-spacing;

  [class*='placeholder'] {
    color: $color-text !important;
  }

  form {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .form-buttons {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

@include gt-sm {
  .form-container {
    margin: $main-spacing * 2 auto;
    max-width: $max-form-width * 1.4;
  }
}

@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.search-box {
    .search-fields {
    margin: $main-spacing 0;
    display: flex;
    flex-direction: column;
    }

    .search-field {
        margin-bottom: $main-spacing;
    }
}

@include gt-sm {
    .search-box {
        .search-fields {
        flex-direction: row;
        }

        .search-field {
            flex: 0 1 300px;
            margin-bottom: 0;
            padding-right: $main-spacing;
        }

        .search-query {
            flex: 1;
        }
    }
}

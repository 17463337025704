@use "sass:math";
@import "../../../config/variables.scss";

.icon-wrapper {
  svg {
    width: 100%;
    height: 100%;
    fill: $color-primary;
  }

  &.light {
    svg {
      fill: $color-text;
    }
  }
  &:not(.no-wrapper) {
    padding: math.div($main-spacing, 1).5;
    background: $color-text;
    width: $main-spacing * 4;
    height: $main-spacing * 4;
  }

  &.transparent {
    background: transparent;
  }
}

@use "sass:math";
@import '../../../config/variables';
@import '../../../config/mixins';

.notification {
  font-family: $font-primary;
  animation-name: show;
  animation-duration: $transition-time;
  cursor: pointer;
  padding: 0;
  z-index: 999;

  .alert {
    margin: 0;
    font-weight: 500;
    box-shadow: 0 0 ($main-spacing * 2) ($main-spacing * -1) rgba(0, 0, 0, 0.25);
  }
}

@include gt-sm {
  .notification {
    padding: math.div($main-spacing, 2);
    border-radius: $main-spacing;
  }
}

@keyframes show {
  0% {
    opacity: 0;
    margin-top: -$main-spacing;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@import '../../../config/variables';
@import '../../../config/mixins.scss';

.notification-container {
  font-family: $font-primary;
  position: fixed;
  right: 0;
  z-index: 999;
  width: 100%;
  @include safe-top(0px);
}

@include gt-sm {
  .notification-container {
    top: 0;
    width: 350px;
  }
}

@import '../../../../../../config/variables.scss';
@import '../../../../../../config/mixins.scss';

.order-summary-wrapper {
  display: flex;
  flex: 0 0 100%;
  flex-direction: row;

  .row {
    display: flex;
    flex-direction: column;
    flex: 0 0 33.333%;
    max-width: 33.3333%;
    margin: $main-spacing;
    margin-left: 0;
    margin-right: 0;

    .cell {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: $main-spacing 0;
      text-align: center;
    }

    .fat {
      display: flex;
      font-weight: 600;
      font-size: 1.1rem;

      @include gt-sm {
        font-size: 1.3rem;
      }
    }
  }
}

@include gt-sm {
  .order-summary-wrapper {
    .row {

      .fat {
        font-weight: 600;
        font-size: 1.8rem;
      }
    }
  }
}

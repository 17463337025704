@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.register-form {
  max-width: $login-form-width * 0.8;
  margin: 0 auto;

  .register-input {

    .input-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    label {
      margin-left: $main-spacing * 3;
    }
    small.text-danger {
      margin-left: $main-spacing * 3;
    }
  }

  .checkbox {
    margin: 10px auto 25px;
  }
}

@include gt-sm {
  .register-form {
    max-width: $login-form-width;
  }
}

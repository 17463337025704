@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.login-logo-wrapper {
  margin: $main-spacing auto $main-spacing *4;
  max-width: $login-button-size * 0.8;
}

@include gt-sm {
  .login-logo-wrapper {
    max-width: $login-button-size * 1.2;
    margin: $main-spacing*5 auto;
  }
}
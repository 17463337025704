@import '../../../config/variables';
@import '../../../config/mixins';

.custom-input {

  .label {
    text-transform: lowercase;
  }

  input {
    &::placeholder {
      color: $color-disabled;
      text-transform: capitalize;
    }
  }
}

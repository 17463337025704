@import '../../../config/variables.scss';

.input-phone-container {
    margin-bottom: $main-spacing * 2;
    width: 100%;

    &.big {
        .PhoneInput {
            height: $login-button-height;
            border-radius: $login-button-height/2;
            padding-left: $main-spacing * 3;
            padding-right: $main-spacing * 3;
            background-color: $color-background;
            display: flex;
            max-width: 100%;
        }
    }

    &.compact {
        .PhoneInput {
            height: 40px;
            background: lighten($color-background, 10%);
            color: $color-text;
            border: none;
            padding: $main-spacing * 0.5;
            padding-left: 1rem;
            font-size: 1rem;
            font-family: $font-primary;
            font-weight: 300;
            box-shadow: $box-shadow-input rgba($color-text, 0.25);
            display: flex;
            &:focus {
                background: rgba($color-text, 0.1);
                box-shadow: $box-shadow-small rgba($color-text, 0.25);
            }
        }
    }


    &.is-invalid {
        .PhoneInput {
            border-color: $color-danger;
        }
    }



    .PhoneInputCountry {
        display: flex;
        align-items: center;
        position: relative;
        margin-right: $main-spacing;
    }

    .PhoneInputCountryIcon {
        display: flex;
        align-items: center;
    }

    .PhoneInputCountrySelect {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        border: 0;
        opacity: 0;
        cursor: pointer;

    }

    .PhoneInputCountryIconImg {
        width: 24px;
    }

    .PhoneInputCountrySelectArrow {
        display: block;
        content: '';
        width: 0.45em;
        height: 0.45em;
        margin-left: 0.5em;
        border-style: solid;
        border-top-width: 0;
        border-bottom-width: 1px;
        border-left-width: 0;
        border-right-width: 1px;
        transform: rotate(45deg);
        opacity: 0.45;
    }

    .PhoneInputInput {
        background: transparent;
        border: none;
        flex: 1;
    }

}
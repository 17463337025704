@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.section-wrapper {
  width: 100%;
  padding: $main-spacing $main-spacing;
  padding-bottom: $main-spacing * 3;
}

@include gt-sm {
  .section-wrapper {
    margin: 0 auto;
    padding: 0;
  }
}

@include gt-lg {
  .section-wrapper {
    margin: $main-spacing auto 0;
  }

  .section-full-width {
    width: 100%;
  }

  .section-half-width {
    width: 49%;
  }
}

.section-highlight {
  padding: $main-spacing;
  margin: $main-spacing 0;
  box-shadow: 0 15px 20px -15px lighten(rgba(#000, 0.4), 30%);
  border-radius: 10px;
  background: lighten($color-background, 10%);

  @include gt-md {
    padding: $main-spacing * 1.5;
  }
}

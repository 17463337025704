@import '../../../config/variables';
@import '../../../config/mixins';

.content-text {
  font-size: 1.1rem;
  text-align: center;
  margin: $main-spacing*3;
}


.payment-button-container {
  text-align: center;
  justify-content: center;
}
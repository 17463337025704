@use "sass:math";
@import '../../../config/variables';
@import '../../../config/mixins';

.gate-status {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  margin-top: $main-spacing;
  margin-bottom: $main-spacing;
  margin-left: auto;
  margin-right: auto;

  @include gt-sm {
    margin-top: $main-spacing;
  }

  @include gt-sm {
    width: 90vw;
  }

  @include gt-md {
    width: 80vw;
  }

  @include gt-lg {
    width: 80vw;
  }

  @include gt-xl {
    width: 65vw;
  }

  .single-status {
    margin-bottom: math.div($main-spacing, 2);
    color: $color-text-alter;
    display: flex;
    flex: 1;
    font-size: 0.9rem;

    @include gt-sm {
      flex: 0 1 33.3333%;
    }

    &.ok {
      background-color: $color-success;

      .status-icon {
        background-color: darken($color-success, 5%);
      }
    }
    &.slow {
      background-color: $color-warning;
      .status-icon {
        background-color: darken($color-warning, 5%);
      }
    }
    &.connection-lost {
      background-color: $color-danger;
      .status-icon {
        background-color: darken($color-danger, 5%);
      }
    }
  }

  .status-icon,
  .status-body {
    padding: $main-spacing;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .status-id {
    font-size: 0.7rem;
    font-weight: 500;
  }

  .status-last-connection-label {
    font-size: 0.7rem;
    font-weight: 500;
    margin-bottom: 0;
  }

  .status-place-value {
    margin: 0 math.div($main-spacing, 4);
  }
}

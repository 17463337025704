@use "sass:math";
@import '../../../config/variables';
@import '../../../config/mixins.scss';

footer {
  position: absolute;

  // @include safe-bottom(60px);
  bottom: 60px;

  width: 100%;
  height: $footer-height * 2;
  padding: $main-spacing 0;
  background-color: $color-primary;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  p {
    margin: 0;
    padding: $main-spacing $table-item-spacing;
    font-size: 0.75rem;
    color: $color-text-button;
  }

  .footerLink {
    padding: $main-spacing $table-item-spacing;
    font-size: 0.75rem;
    color: $color-text-button;
  }
}

@include gt-xs {
  footer {
    flex-direction: row;
    height: $footer-height;

    .p {
      padding: math.div($main-spacing, 2) $table-item-spacing;
    }

    a.footerLink {
      color: $color-text-light;
      padding: math.div($main-spacing, 2) $table-item-spacing;
      border-right: solid 2px rgba($color-background, 0.5);
    }
  }
}

@include gt-sm {
  footer {
    bottom: 0px;
  }
}

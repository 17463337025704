@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.restore-pass-form {
  max-width: $login-form-width * 0.8;
  margin: 0 auto;
  max-height: 600px;
}

@include gt-sm {
  .restore-pass-form {

    max-width: $login-form-width;
    margin: 0 auto;
  }
}

@use "sass:math";
@import '../../../config/variables';
@import '../../../config/mixins.scss';

.mobile-logo-container {
  order: 0;
  display: flex;
  justify-content: flex-end;
  padding: math.div($main-spacing, 2);
  z-index: 900;

  &:hover {
    cursor: pointer;
  }

  img {
    display: block;
    height: 30px;
    //min-width: 200px;
  }

  &.small {
    img {
      height: $main-logo-size / 3.5;
    }
  }

  &.only-bottom-margin {
    padding: 0;
    margin-bottom: $main-spacing * 2;
  }
}

@include gt-sm {
  .mobile-logo-container {
    display: none;
  }
}

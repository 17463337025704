@use "sass:math";
@import '../../../../config/variables.scss';
@import '../../../../config/mixins.scss';

.bar-wrapper {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  left: 50%;

  .bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $color-disabled;
    height: math.div($main-spacing, 5);
    flex: 0 0 100%;
    margin-top: $main-spacing * 2;

    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  ;

  }

  .bar-done {
    background: $color-success;
  }
}

@include gt-sm {
  .bar-wrapper {
    .bar {
      margin-top: 20px;
    }
  }
}

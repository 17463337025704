@import "../../../config/variables.scss";
@import "../../../config/mixins.scss";

.fields-grid {
  display: flex;
  margin: 0 -1 * $main-spacing;

  form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1;
    .form-field {
      flex: 0 0 100%;
      padding: $main-spacing $main-spacing;
      justify-content: flex-start;
    }

    .form-field.field-type-checkbox {
      flex: 0 0 100%;
    }

    .form-buttons {
      flex: 1 0 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: $main-spacing;
    }

    .form-field-title {
      font-size: 0.8rem;
    }

    @include gt-md {
      .form-field {
        flex: 0 0 50%;
      }
    }

    @include gt-lg {
      .form-field {
        flex: 0 0 33.333%;
      }
    }

    @include gt-xl {
      .form-field {
        flex: 0 0 25%;
      }
    }
  }
}

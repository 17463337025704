@use "sass:math";
@import '../../../../../../config/variables.scss';
@import '../../../../../../config/mixins.scss';

.single-wrapper {
  padding: math.div($main-spacing, 4);
  width: 100%;

  .storage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    width: 100%;
    height: $box-size;
    cursor: pointer;
    margin: math.div($main-spacing, 2) 0;

    background-image: url(/assets/icons/storage-background.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    color: #fff;
  


    &.warning {
      border-color: $color-danger;

      .storage-warning-overlay {
        background-color: rgba(lighten($color-danger, 10%), 0.75);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .details {
      position: relative;
      font-size: 0.8rem;
      display: flex;
      width: 90%;
      justify-content: space-between;
    }

    .main-info {
      font-size: 1.2rem;
      display: flex;
      width: 90%;
      justify-content: center;

      strong {
        text-align: center;
      }
    }

    .sec-info {
      font-size: 0.7rem;
      width: 90%;

      i {
        position: absolute;
        top: 0px;
        right: -5px;
        font-size: 1rem;
      }
    }

    .size {
      &::after {
        position: absolute;
        content: '2';
        display: block;
        width: 10px;
        height: 10px;
        top: 0;
        right: -10px;
        font-size: 0.7rem;
      }
    }

    .sec-info,
    .main-info {
      position: relative;
      text-align: start;
    }
  }
}

@include gt-xs {
  .single-wrapper {
    width: 50%;

    .storage {
      .sec-info {
        i {
          top: 0px;
          right: 0px;
        }
      }
    }
  }
}

@include gt-sm {
  .single-wrapper {
    width: 33.33%;

    .storage {
      height: $big-box-size;
      justify-content: space-between;
      padding: $main-spacing;
    }
  }
}

@include gt-md {
  .single-wrapper {
    .storage {
      height: $big-box-size;
      align-items: center;

      .main-info {
        font-size: 1.4rem;
        font-weight: 500;
      }

      .sec-info,
      .details {
        font-size: 0.9rem;

        i {
          font-size: 1.4rem;
          right: -20px;
        }
      }
    }
  }
}

@use "sass:math";
@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.input-container {
    position: relative;
    margin-bottom: $main-spacing * 2;
    width: 100%;
    display: flex;
    flex-direction: column;

    ::placeholder {
        color: rgba($color-text, 0.25);
    }

    &.nomargin {
        margin-bottom: 0;
    }

    &.hidden {
        display: none;
    }

    &.big {
        .input {
            height: $login-button-height;
            border-radius: $login-button-height/2;
            margin: 0 auto;
            padding-left: $main-spacing * 3;
            padding-right: $main-spacing * 3;
            background-color: $color-background;

            &:active,
            &:focus {
                border-color: $color-active;
                background-color: $color-background;
            }
        }
    }

    &.compact {
        .input {
            background: lighten($color-background, 10%);
            color: $color-text;
            border: none;
            padding: $main-spacing * 1.5;
            padding-left: 1rem;
            font-size: 1rem;
            font-family: $font-primary;
            font-weight: 300;
            box-shadow: $box-shadow-input rgba($color-text, 0.25);

            &:focus {
                background: rgba($color-text, 0.1);
                box-shadow: $box-shadow-small rgba($color-text, 0.25);
            }
        }



    }

    .input.is-invalid {
        border-color: $color-danger;
    }


    .input.input-color {
        padding: 0;
    }
}

.input-icon {
    position: absolute;
    left: math.div($main-spacing, 2);
    top: 50%;
    transform: translateY(-50%);
    width: 1.2rem;
    height: 1.2rem;
}
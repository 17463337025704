
@font-face {
  font-family: 'AzoSans';
  src: url('/assets/font/azosansbold-webfont.woff2') format('woff2'),
       url('/assets/font/azosansbold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'AzoSans';
  src: url('/assets/font/azosansmedium-webfont.woff2') format('woff2'),
       url('/assets/font/azosansmedium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AzoSans';
  src: url('/assets/font/azosansregular-webfont.woff2') format('woff2'),
       url('/assets/font/azosansregular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AzoSans';
  src: url('/assets/font/azosanslight-webfont.woff2') format('woff2'),
       url('/assets/font/azosanslight-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@use "sass:math";
@import '../../../config/variables';
@import '../../../config/mixins.scss';

.buttons-container {
  @include gt-sm {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
  }

  &.max {
    justify-content: stretch;
    .button {
      flex: 1;
    }
  }
  &.column {
    flex-direction: column;
  }

  &.space-between {
    justify-content: space-between;
  }
}

@include lt-xs {
  .buttons-container {
    .button {
      margin-bottom: math.div($main-spacing, 4);
      margin-top: 0;
    }
  }
}

@include gt-sm {
  .buttons-container {
    .button:not(:first-child) {
      margin-left: 5px;
    }
  }
}

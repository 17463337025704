@import '../../../config/variables';
@import '../../../config/mixins';

.section-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .section-header {
    color: $color-text;
    display: flex;
    align-items: center;
    width: 100%;

    h3 {
      color: $color-text;
      font-weight: 700;
      margin-top: 0;
    }
  }

  .no-margin {
    margin-bottom: 0;
  }
}

@include gt-sm {
  .section-header-container {
    margin-bottom: $main-spacing * 1.5;

    &.no-margin {
      margin-bottom: 0;
    }

    .section-header {
      max-width: $page-header-width;
    }
  }
}

@use "sass:math";
@import '../../../../config/variables.scss';
@import '../../../../config/mixins.scss';

.main-menu-item {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-top: 2px solid $color-inactive;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: $main-spacing;
  color: $color-inactive;
  font-size: 0.9rem;
  //border: none;

  &:link {
    color: $color-inactive;
  }
  &:visited {
    color: $color-inactive;
  }

  &:hover {
    border-top: 2px solid $color-primary-light;

    cursor: pointer;


  }

  &.active {
    border-top: 2px solid $color-primary;
    font-weight: 500;
    color: $color-inactive;
  }


  .main-menu-item_logo {
    width: $menu-icon-small;
    height: $menu-icon-small;
    margin-bottom: math.div($main-spacing, 2);
    fill: $color-inactive;
  }
}

@include gt-sm {
  .main-menu-item_logo {
    width: $menu-icon-medium;
    height: $menu-icon-medium;
    margin-bottom: $main-spacing;
    fill: $color-inactive;
  }
  .main-menu-item {
    border-top: none;
    border-bottom: none;

    &:hover {
      border-top: none;
    border-bottom: none;
    }

    &.active {
      border-top: none;
      border-bottom: none;
    }
  }
}

@include gt-md {
  .main-menu-item_logo {
    width: $menu-icon-big;
    height: $menu-icon-big;
  }
}

@include gt-lg {
  .main-menu-item {
    display: flex;
    border-top: none;
    border-bottom: none;
    flex-direction: row;
    justify-content: flex-end;

    .main-menu-item_logo {
      width: $menu-icon-big;
      height: $menu-icon-big;
      margin-right: 10px;
      margin-bottom: 0;
    }

    &.active {
      border-top: none;
      border-bottom: none;
    }

    &:hover {
      border-top: none;
      border-bottom: none;
    }

    .main-menu-item_text {
      line-height: $menu-icon-big;
      font-weight: 400;
    }
  }
}

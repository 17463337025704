@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.login-form {
  max-width: $login-form-width * 0.8;
  margin: 0 auto;
  max-height: auto;
}

.login-input {

  .input-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  label {
    margin-left: $main-spacing * 3;
  }

  &:first-of-type {
    margin-top: 10%;
  }

  .input {
    border: 2px solid $color-primary;
    height: $login-button-height;
    width: $login-button-size;
    border-radius: $login-button-height/2;
    padding-left: $main-spacing *2;
    padding-right: $main-spacing *2;
    background-color: $color-background;

    &:active, &:focus {
      border-color: $color-active;
      background-color: $color-background;
    }
  }
}

@include gt-xs {
  .login-input {
    &:first-of-type {
      margin-top: 25%;
    }
  }
}

@include gt-sm {
  .login-form {
    max-width: $login-form-width;
    margin: 0 auto;
    max-height: none;
  }

  .login-input {
    label {
      margin-left: $main-spacing * 5;
    }
    .input {
      width: $login-button-size * 1.2;
    }

    &:first-of-type {
      margin-top: 30%;
    }
  }
}

@import '../../../../../config/variables.scss';
@import '../../../../../config/mixins.scss';

.step-stock-wrapper {
  form {
    width: 100%;
  }

  .stocks-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    margin-left: -$main-spacing/2;
  }
}

@include gt-sm {
  .stocks-wrapper {
    flex-direction: row;
    margin-bottom: $main-spacing * 2;
  }
}

.stock-group {
  flex: 0 0 100%;
}


.group-stocks-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$main-spacing/2;
}
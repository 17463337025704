@use "sass:math";
@import '../../../../../config/variables.scss';
@import '../../../../../config/mixins.scss';

.no-discounts {
  color: $color-inactive;
}

.discounts-wrapper {
  display: flex;
  flex-direction: column;
}

.single-discont {
  background-color: $color-active;
  padding: $main-spacing;
  margin: math.div($main-spacing, 2) 0;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
}

@import '../../../../../config/variables.scss';
@import '../../../../../config/mixins.scss';

.order-summary-wrapper {
  width: 100%;
}

.separator {
  height: 2px;
  margin-bottom: $main-spacing;
  background-color: $color-disabled;
}

.rules-embed {
  width: 100%;
  height: 50vh;
}

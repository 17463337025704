@use "sass:math";
@import '../../../../../../config/variables';
@import '../../../../../../config/mixins';

.payment-wrapper {
  width: 275px;
  margin: 0 auto;
}

.bank-input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tpay-pay-btn {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  margin: math.div($main-spacing, 2) 0;
  background: lighten($color-background, 10%);
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  width: 275px;

  transition: $transition-time background-color, $transition-time border-color;
  box-shadow: $box-shadow-small rgba($color-text, 0.25);
  text-transform: none;
  border: 2px solid $color-primary;
  color: $color-primary;
  padding: 8px 20px;
  font-weight: 400;

  img {
    width: 150px;
    height: auto;
    margin: 20px;
  }

  &:hover {
    box-shadow: inset 0 -1px 1px -1px $color-primary;
  }
}

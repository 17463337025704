@import '../../../config/variables';
@import '../../../config/mixins';

.page-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //padding: $main-spacing*2 0;
  font-size: 1.2rem;
  font-weight: 500;

  .page-header {
    color: $color-text;
    display: flex;
    align-items: center;
    width: 100%;
    margin: $main-spacing 0;
  }
}

@include gt-sm {
  .page-header-container {
    margin-bottom: $main-spacing * 1.5;
    .page-header {
      max-width: $page-header-width;
    }
  }
}

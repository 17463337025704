@import "../../config/variables";
@import "../../config/mixins";

.page-not-found {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $color-primary;
  padding: $main-spacing;

  h1 {
    font-weight: 700;
    font-size: 9rem;

    @include gt-sm {
      font-size: 12rem;
    }
  }
}

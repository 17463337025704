@use "sass:math";
@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.update-info-bar {
  background-color: $color-primary;
  padding: $main-spacing;
  display: block;
  cursor: pointer;
  font-size: 0.9rem;
  transition: $transition-time background-color;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 999999;
  box-shadow: 0 0 math.div($main-spacing, 2) math.div($main-spacing, 4) rgba(0, 0, 0, 0.5);
  @include safe-top(0);
  &:hover {
    background-color: lighten($color-primary, 10%);
  }

  .update-info-icon {
    margin-right: math.div($main-spacing, 4);
  }
}
